<template>
  <div
    class="Box"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <div>
      <div style="float: left">
        <el-select
          v-model="find.type"
          placeholder="请选择仓库类型"
          clearable
          @change="search"
        >
          <el-option
            v-for="item in list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div
        style="width: 200px; float: left; margin-right: 15px; margin-left: 15px"
      >
        <el-input
          v-model="find.keyword"
          placeholder="请输入仓库库名称或代码"
          clearable
          @keyup.enter.native="search"
        ></el-input>
      </div>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button @click="reset">重置</el-button>
      <div style="margin: 8px">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="Add"
          >新建</el-button
        >
      </div>
    </div>
    

    <div class="content">
      <el-table
        border
        :data="tableData"
        @sort-change="sortTable"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        :cell-style="{ fontSize: '13px', padding: '7px 0'}"
        :header-cell-style="{
            fontSize: '13px',
            padding: '9px 0',
            marginTop: '10px',
            background: '#EBEEF5',
        }">
        <el-table-column label="仓库名称" align="center" prop="storageName">
        </el-table-column>
        <el-table-column label="属性" align="center" prop="typeName" >
          <div slot="header">
            <span>属性</span>
            <el-tooltip class="item" effect="dark" content="FBA仓库库存总量每小时更新一次" placement="top">
              <i class="iconfont icon-wenhao"></i>
            </el-tooltip>
          </div>
        </el-table-column>
        <el-table-column label="产品种类" align="center" prop="productCount" >
        </el-table-column
        ><el-table-column label="可用量" align="center" prop="goodQuantity">
        </el-table-column
        ><el-table-column
          label="待检量"
          align="center"
          prop="toCheckQuantity"
        >
        </el-table-column
        ><el-table-column label="待发货量/锁定量" align="center" prop="lockQuantity" >
          <div slot="header">
            <span>待发货量/锁定量</span>
            <el-tooltip class="item" effect="dark" content="FBA仓库库存总量每小时更新一次" placement="top">
            </el-tooltip>
            <el-tooltip placement="top">
              <div slot="content">
                  FBA锁定库存 = reserveved transfer+reserved processing+reserved customerorders，非FBA仓锁定量=可用锁定量+次品锁定量
              </div>
                <i class="iconfont icon-wenhao"></i>
            </el-tooltip>
          </div>
        </el-table-column
        ><el-table-column label="在途量" align="center" prop="onWayQuantity" >
            <div slot="header">
              <span>在途量</span>
              <el-tooltip class="item" effect="dark" content="FBA仓库库存总量每小时更新一次" placement="top">
              </el-tooltip>
              <el-tooltip placement="top">
                <div slot="content">
                    FBA仓库，在途按FBA发货单已发货，未接收的数量计算
                </div>
                <i class="iconfont icon-wenhao"></i>
              </el-tooltip>
            </div>
        </el-table-column
        ><el-table-column label="次品量" align="center" prop="badQuantity">
        </el-table-column
        ><el-table-column label="总量" align="center" prop="total" >
        </el-table-column
        ><el-table-column label="货值" align="center" prop="value" >
          <div slot="header">
            <span>货值</span>
            <el-tooltip class="item" effect="dark" content="FBA仓库库存总量每小时更新一次" placement="top">
            </el-tooltip>
            <el-tooltip placement="top">
              <div slot="content">
                  FBA仓库库存，根据FNSKU所配对产品的采购成本统计得出
              </div>
              <i class="iconfont icon-wenhao"></i>
            </el-tooltip>
          </div>
        </el-table-column
        ><el-table-column label="库存成本" align="center" prop="value" >
          <div slot="header">
            <span>库存成本</span>
            <el-tooltip class="item" effect="dark" content="FBA仓库库存总量每小时更新一次" placement="top">
            </el-tooltip>
            <el-tooltip placement="top">
              <div slot="content">
                  FBA仓库库存，根据FNSKU所配对产品的采购成本+默认头程成本统计得出
              </div>
              <i class="iconfont icon-wenhao"></i>
            </el-tooltip>
          </div>
        </el-table-column>
        <!-- <el-table-column label="操作" width="165" align="center">
          <template slot-scope="scope">
            <el-link
              style="margin-right: 10px"
              :underline="false"
              type="primary"
              icon="el-icon-edit"
              @click="asyncEdit(scope.row.id)"
              >编 辑</el-link
            >
            <el-link
              :underline="false"
              type="danger"
              icon="el-icon-delete"
              @click="asyncDelete(scope.row.id)"
              >删 除</el-link
            >
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next"
        :total="total"
        ></el-pagination>
    </div>
    <!-- 新增 -->
    <el-dialog width="760px" :title="title" :visible.sync="addVisible">
      <el-form
        ref="formInline"
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        label-position="left"
        label-width="80px"
      >
        <el-form-item
          label="仓库名称"
          prop="storageName"
          :rules="[
            { required: true, message: '请输入仓库名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="formInline.storageName"
            placeholder="仓库名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="仓库代码"
          prop="storageCode"
          :rules="[
            { required: true, message: '请输入仓库代码', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="formInline.storageCode"
            placeholder="仓库代码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="仓库类别"
          prop="type"
          :rules="[
            { required: true, message: '请选择仓库类别', trigger: 'change' },
          ]"
        >
          <el-select v-model="formInline.type" placeholder="请选择">
            <el-option
              v-for="item in list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="统计币种"
          prop="curreny"
          :rules="[
            { required: true, message: '请选择币种', trigger: 'change' },
          ]"
        >
          <el-select v-model="formInline.curreny" placeholder="统计币种">
            <el-option
              v-for="item in money"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" 负责人">
          <el-input
            v-model="formInline.contact"
            placeholder="负责人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="formInline.tel" placeholder="联系电话"></el-input>
        </el-form-item>
        <el-form-item label="仓库地址">
          <el-input
            v-model="formInline.address"
            placeholder="仓库地址"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="仓库名称">
          <el-input v-model="formInline.name" placeholder="仓库名称"></el-input>
        </el-form-item> -->
        <el-form-item label="备注" prop="desc">
          <el-input
            cols="72%"
            type="textarea"
            v-model="formInline.tips"
            :autosize="{ minRows: 5, maxRows: 5 }"
          ></el-input>
        </el-form-item>
        <div style="text-align: right; margin-top: 30px">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button
            type="primary"
            @click="confirm"
            v-show="this.title == '新增供应商'"
            >确定</el-button
          >
          <el-button
            type="primary"
            @click="sureConfirm"
            v-show="this.title == '编辑仓库'"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  listPageAll,
  add,
  delSupplier,
  updateInit,
  update,
} from "@/api/InventoryManage/repository.js";
export default {
  data() {
    return {
      loading:true,
      total: 0,
      clearable: "",
      title: "",
      id: "",
      obj: [],
      tableData: [
        {
          type: "",
        },
      ],
      list: [
        {
          value: "0",
          label: "本地仓库",
        },
        {
          value: "1",
          label: "海外仓库",
        },
      ],
      money: [
        {
          value: "CNY",
          label: "CNY",
        },
      ],
      find: {
        keyword: "",
        type: "",
      },
      // value: "",
      addVisible: false,
      formInline: {
        storageName: "",
        storageCode: "",
        type: "",
        curreny: "CNY",
        contact: "",
        tel: "",
        address: "",
        tips: "",
      },
      pageInfo: {
        pageSize: "",
        current: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const res = await listPageAll();
        if ((Object.is(res.code), 200)) {
          this.loading = false;
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total;
          this.tableData.map(function (val) {
            if (val.type === 0) {
              val.type = "本地仓库";
            } else {
              val.type = "海外仓库";
            }
          });
          // console.log("oooooo", this.tableData.map((x) => x.type));
        } else {
          this.$message.error(res.message);
          this.loading = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //编辑修改
    async getData2() {
      let params = {
        address: this.formInline.address,
        contact: this.formInline.contact,
        storageName: this.formInline.storageName,
        storageCode: this.formInline.storageCode,
        type: this.formInline.type == "海外仓库" ? 1 : 0,
        tel: this.formInline.tel,
        tips: this.formInline.tips,
        curreny: this.formInline.curreny,
      };
      try {
        const res = await update({ id: this.id, ...params });
        if ((Object.is(res.code), 200)) {
          this.getData();
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData3() {
      try {
        let params = {
          type: this.find.type,
          keyword: this.find.keyword,
        };
        const res = await listPageAll(params);
        if ((Object.is(res.code), 200)) {
          this.tableData = res.data.values;
          this.total = res.data.pageInfo.total;
          this.tableData.map(function (val) {
            if (val.type === 0) {
              val.type = "本地仓库";
            } else {
              val.type = "海外仓库";
            }
          });
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //新增
    async getData1() {
      try {
        let params = {
          address: this.formInline.address,
          contact: this.formInline.contact,
          storageName: this.formInline.storageName,
          storageCode: this.formInline.storageCode,
          type: this.formInline.type,
          tel: this.formInline.tel,
          tips: this.formInline.tips,
          curreny: this.formInline.curreny,
        };
        const res = await add(params);
        if ((Object.is(res.code), 200)) {
          console.log(res.data);
        } else {
          this.$message.error(res.message);
        }
        this.getData();
      } catch (error) {
        console.log(error);
      }
    },
    //新增按钮
    Add() {
      this.title = "新增供应商";
      this.formInline = {};
      this.addVisible = true;
    },
    //新增确认
    confirm() {
      this.getData1();
      this.addVisible = false;
    },
    //编辑
    // asyncEdit(id) {
    //   this.title = "编辑仓库";
    //   this.id = id;
    //   updateInit(id).then((res) => {
    //     if (res.data.code != 200) {
    //       this.$message.error(res.data.message);
    //       return;
    //     }
    //     this.formInline = {
    //       address: res.data.data.address,
    //       contact: res.data.data.contact,
    //       storageName: res.data.data.storageName,
    //       storageCode: res.data.data.storageCode,
    //       curreny: res.data.data.curreny == 0 ? "CNY" : "CNY",
    //       tips: res.data.data.tips,
    //       tel: res.data.data.tel,
    //       type: res.data.data.type == 0 ? "本地仓库" : "海外仓库",
    //     };
    //   });
    //   this.addVisible = true;
    // },
    search() {
      this.getData3();
      this.pageInfo.current = 1;
    },
    //删除
    // asyncDelete(id) {
    //   this.$confirm("删除该信息, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     delSupplier(id).then((res) => {
    //       if (res.data.code == 200) {
    //         this.getData(); // 刷新列表
    //         this.$message.success(res.data.message);
    //       } else {
    //         this.$message.error(res.data.message);
    //       }
    //     });
    //   });
    // },
    //编辑确认
    sureConfirm() {
      this.getData2();
      this.addVisible = false;
      this.getData();
    },
    reset() {
      this.pageInfo = {
        current: "",
        pageSize: "",
      };
      (this.find = {
        keyword: "",
        type: "",
      }),
        this.getData();
    },
    handleCurrentChange(val) {
      this.pageInfo.current = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getData();
    },
  },
};
</script>


<style scoped lang='scss'>
.Box{
    width: 100%;
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    .content{
        flex: 1;
        overflow: auto;
        padding-top: 10px;
    }
}
</style>